import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from '@src/common/transform';
import { addEventListener } from '@ifeng-fe/ui_base';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import LowBrower from './lowBrower';
import Header from './header';
import Navigation from './navigation';
import NewsBox0 from './newsBox0';
import NewsBox1 from './newsBox1';
import NewsBox2 from './newsBox2';
import NewsBox3 from './newsBox3';
import NewsBox4 from './newsBox4';
import NewsBox5 from './newsBox5';
import NewsBox6 from './newsBox6';
import NewsBox7 from './newsBox7';
import NewsBox8 from './newsBox8';
import NewsBox9 from './newsBox9';
import NewsBox11 from './newsBox11';
import NewsBox12 from './newsBox12';
import Footer from './footer';
import QrCode from './qrCode';
import FloatAds from './floatAds';
import AddRed from './addRed';
import ChipView from './chipView';

const randFilterHandle = (dataArray = []) => {
    let res = [];

    if (!dataArray || dataArray.length === 0) {
        return res;
    }
    try {
        dataArray = JSON.parse(JSON.stringify(dataArray));
        const result = dataArray.map(item => {
            // 是否随机显示一条数据
            if (item.marquee && item.marquee === 1) {
                let result = [item];
                let num = 0;

                if (item.children && item.children.length > 0) {
                    result = result.concat(item.children);
                    delete result?.[0]?.children;
                    num = Math.floor(Math.random() * result.length);
                }

                return result[num];
            } else {
                return item;
            }
        });

        if (result && result.length > 0) {
            res = result;
        } else {
            res = [];
        }
    } catch (error) {
        console.error(error);
    }

    return res;
};

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    shopDataInit = (data = []) => {
        const result = data.map(item => {
            return item.regionA?.[0];
        });

        return result;
    };

    state = {
        turnRed:
            this.props.content && this.props.content.topImg && this.props.content.topImg.isShow
                ? this.props.content.topImg.isShow
                : false,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
        this.unHandleClick = addEventListener(document, 'click', this.handleClick);
    }

    componentWillUnmount() {
        this.unHandleClick();
    }

    handleCloseTurnRed = () => {
        this.setState({
            turnRed: false,
        });
    };

    // 全站渠道标记传递，文章页的任意跳转
    handleClick = e => {
        const tag = this.getATag(e.target);

        if (!tag) return;

        let localHref =
            (tag.attributes['sign-trs-href'] ? tag.attributes['sign-trs-href'].value : '') ||
            (tag.attributes['href'] ? tag.attributes['href'].value : '');

        if (
            localHref &&
            localHref !== 'undefined' &&
            localHref !== '' &&
            localHref !== '#' &&
            localHref.indexOf('javascript:') === -1
        ) {
            localHref = localHref.trim();
            const localSearch = location.search;
            const localHash = location.hash;

            tag.setAttribute('sign-trs-href', localHref);

            let newUrl = '';
            let inWhitelist = false;
            const whitelist = ['//dol.deliver.ifeng.com/'];

            for (let i = 0, len = whitelist.length; i < len; i++) {
                if (localHref.indexOf(whitelist[i]) > -1) {
                    inWhitelist = true;
                }
            }
            // 传递下划线开头的统计
            const curSrc = this.getParam(localSearch, localHash);

            if ((localSearch || localHash) && curSrc && !inWhitelist) {
                if (localHref.indexOf('?') > -1) {
                    newUrl = `${localHref}&${curSrc}`;
                } else {
                    newUrl = `${localHref}?${curSrc}`;
                }

                tag.setAttribute('href', newUrl);
            }
        }
    };

    getATag = tag => {
        if (!tag) {
            return null;
        }

        if (tag.tagName !== 'A') {
            return this.getATag(tag.parentElement);
        } else {
            return tag;
        }
    };

    getSign = (localSearch, ret) => {
        const localSearchArr = localSearch.split('#');

        for (let i = 0; i < localSearchArr.length; i++) {
            const localParam = localSearchArr[i];

            if (localParam.indexOf('_') === -1) continue;

            const localParamArr = localParam.split('?');

            for (let j = 0; j < localParamArr.length; j++) {
                const localParam2 = localParamArr[j];

                if (localParam2.indexOf('_') === -1) continue;

                const localParam2Arr = localParam2.split('&');

                for (let m = 0; m < localParam2Arr.length; m++) {
                    const localParam3 = localParam2Arr[m];

                    if (localParam3.indexOf('_') === -1) continue;

                    const localParam3Arr = localParam3.split('|');

                    for (let k = 0; k < localParam3Arr.length; k++) {
                        const localParam4 = localParam3Arr[k];

                        if (localParam4.indexOf('_') !== 0) continue;

                        if (ret === '') {
                            ret += localParam4;
                        } else {
                            ret = `${ret}&${localParam4}`;
                        }
                    }
                }
            }
        }

        return ret;
    };

    getParam = (localSearch, localHash) => {
        let ret = '';

        if (localSearch.indexOf('_zbs') > -1) {
            ret = this.getSign(localSearch, ret);
        }
        if (localHash.indexOf('_zbs') > -1) {
            ret = this.getSign(localHash, ret);
        }

        return ret;
    };

    render() {
        const { turnRed } = this.state;
        const {
            content,
            content: {
                newsSlide8,
                newsSlide9,
                hotShop1,
                hotShop2,
                newsShop1,
                newsShop2,
                shopInChoice,
                shopList0,
                shopInFinance,
                shopInStock,
                shopInMil,
                shopInTech,
                shopInAuto,
                shopInSports,
                shopInEnt,
                shopInMovie,
                shopInFood0,
                shopGroup1,
                shopInFood1,
                shopInFashion,
                shopRegionList2,
                shopRegionList4,
                shopInCultureBook,
                shopInEdu,
                shopInGuoxue,
                shopInHealth,
            },
        } = this.props;

        const redConfig = {
            isIpad: content.isIpad ? content.isIpad : false,
            topImg: content.topImg ? content.topImg : {},
        };

        const headerData = {
            logo: content.logo,
            danate: content.donate,
            search: content.search,
            jubaoData: content.jubaoData,
        };

        const navigationData = {
            nav: content.navigation,
            subNav: content.subNavigation,
            subNavArea: content.subNavArea,
        };

        // 热点、要闻、视频
        const boxData0 = {
            leftData: {
                newsSlide0: content.newsSlide0,
                newsSlide1: content.newsSlide1,
                newsSlide2: content.newsSlide2,
                newsSlide3: content.newsSlide3,
                newsSlide4: content.newsSlide4,
                newsSlide5: content.newsSlide5,
                newsSlide6: content.newsSlide6,
                newsSlide7: content.newsSlide7,
                newsSlide8,
                newsSlide9,
                hotTitle: content.hotTitle,
                hotNews1: content.hotNews1,
                hotNews2: content.hotNews2,
                hotNews3: content.hotNews3,
                shopInHotNews1: hotShop1,
                shopInHotNews2: hotShop2,
                hotNewsAd: content.hotNewsAd,
                replaceShopConfig: content.replaceShopConfig,
                hotRSAd: content.hotRSAd,
                hotDRSAd: content.hotDRSAd,
                replaceShopConfig2: content.replaceShopConfig2,
                slideAd1: content.slideAd1,
                slideAd2: content.slideAd2,
            },
            centerData: {
                newsTitle: content.newsTitle,
                newsTop: content.newsTop,
                newsList0: randFilterHandle(content.newsList0),
                newsList1: content.newsList1,
                newsList2: content.newsList2,
                shopInNews0: newsShop1,
                shopInNews1: newsShop2,
                govList: content.govList,
                newsAd: content.newsAd,
                newsRSAd: content.newsRSAd,
                replaceShopConfig: content.replaceShopConfig,
                newsARSAd: content.newsARSAd,
                replaceShopConfig2: content.replaceShopConfig2,
                areaList: {
                    hubei: content.hubei,
                    shandong: content.shandong,
                    qingdao: content.qingdao,
                    hunan: content.hunan,
                    shaanxi: content.shaanxi,
                    jiangxi: content.jiangxi,
                    guangdong: content.guangdong,
                    hainan: content.hainan,
                    anhui: content.anhui,
                    hebei: content.hebei,
                    zhejiang: content.zhejiang,
                    chongqing: content.chongqing,
                    heilongjiang: content.heilongjiang,
                    jilin: content.jilin,
                    gansu: content.gansu,
                    henan: content.henan,
                    sichuan: content.sichuan,
                    ningbo: content.ningbo,
                    jiangsu: content.jiangsu,
                    fujian: content.fujian,
                    shenzhen: content.shenzhen,
                    shanxi: content.shanxi,
                    hubeiLast: content.hubeiLast,
                    shandongLast: content.shandongLast,
                    qingdaoLast: content.qingdaoLast,
                    hunanLast: content.hunanLast,
                    shaanxiLast: content.shaanxiLast,
                    jiangxiLast: content.jiangxiLast,
                    guangdongLast: content.guangdongLast,
                    hainanLast: content.hainanLast,
                    anhuiLast: content.anhuiLast,
                    hebeiLast: content.hebeiLast,
                    zhejiangLast: content.zhejiangLast,
                    chongqingLast: content.chongqingLast,
                    heilongjiangLast: content.heilongjiangLast,
                    jilinLast: content.jilinLast,
                    gansuLast: content.gansuLast,
                    henanLast: content.henanLast,
                    sichuanLast: content.sichuanLast,
                    ningboLast: content.ningboLast,
                    jiangsuLast: content.jiangsuLast,
                    fujianLast: content.fujianLast,
                    shenzhenLast: content.shenzhenLast,
                    shanxiLast: content.shanxiLast,
                },
            },
            rightData: {
                phtvTitle: content.phtvTitle,
                phtvSlide: content.newsSlidePhtv,
                phtvVideoInfo: content.phtvVideoInfo,
                phtvNewProgramTitle: content.phtvNewProgramTitle,
                phtvNewProgramList: content.phtvNewProgramList,
                phtvPlaybackTitle: content.phtvPlaybackTitle,
                phtvPlaybackList: content.phtvPlaybackList,
                phtvPeopleTitle: content.phtvPeopleTitle,
                phtvPeopleNewList: content.phtvPeopleNewList,
                phtvPeopleNameList: content.phtvPeopleNameList,
                phtvAd: content.phtvAd,
            },
        };

        // 凤凰精选、凰家尚品、风直播
        const boxData1 = {
            leftData: {
                choiceTitle: content.choiceTitle,
                choiceList0: content.choiceList0,
                shopInChoice,
                choiceList1: content.choiceList1,
                choiceListAd: content.choiceListAd,
                choiceHardAd: content.choiceHardAd,
                promoteInChoice: content.promoteInChoice,
                replaceShopConfig: content.replaceShopConfig,
                choiceRSAd: content.choiceRSAd,
            },
            centerData: {
                shopTitle0: content.shopTitle0,
                shopList0,
                shopListAd: content.shopListAd,
            },
            rightData: {
                fLiveTitle: content.fLiveTitle,
                fLiveAd: content.fLiveAd,
                fLiveListAd: content.fLiveListAd,
            },
            shopAndFLiveHardAd: content.shopAndFLiveHardAd,
        };

        // 财经、股票、行情
        const boxData2 = {
            leftData: {
                stockTitle: content.stockTitle,
                stockList0: content.stockList0,
                shopInFinance,
                financeList1: content.financeList1,
                financeListAd: content.financeListAd,
                financeHardAd: content.financeHardAd,
                promoteInFinance: content.promoteInFinance,
                financeRSAd: content.financeRSAd,
                replaceShopConfig2: content.replaceShopConfig2,
            },
            centerData: {
                financeTitle: content.financeTitle,
                financeList0: content.financeList0,
                shopInStock,
                stockList1: content.stockList1,
                stockListAd: content.stockListAd,
                stockRSAd: content.stockRSAd,
                replaceShopConfig2: content.replaceShopConfig2,
            },
            rightData: {
                quotationTitle: content.quotationTitle,
                quotationImg: content.quotationImg,
                quotationSearch: content.quotationSearch,
                quotationList: content.quotationList,
                quotationListAd: content.quotationListAd,
            },
            stockAndQuotationHardAd: content.stockAndQuotationHardAd,
        };

        // 军事、科技、数码
        const boxData3 = {
            leftData: {
                milTitle: content.milTitle,
                milList0: content.milList0,
                shopInMil,
                milList1: content.milList1,
                cultureBookListAd: content.cultureBookListAd,
                cultureBookHardAd: content.cultureBookHardAd,
                promoteInCultureBook: content.promoteInCultureBook,
                milRSAd: content.milRSAd,
                replaceShopConfig2: content.replaceShopConfig2,
            },
            centerData: {
                techTitle: content.techTitle,
                techList0: content.techList0,
                shopInTech,
                techList1: content.techList1,
                milListAd: content.milListAd,
                techRSAd: content.techRSAd,
                replaceShopConfig: content.replaceShopConfig,
            },
            rightData: {
                digitalTitle: content.digitalTitle,
                digitalList: content.digitalList,
                historyListAd: content.historyListAd,
            },
            milAndHistoryHardAd: content.milAndHistoryHardAd,
        };

        // 旅游、汽车、新车导购
        const boxData4 = {
            leftData: {
                travelTitle: content.travelTitle,
                travelList: content.travelList,
                travelListAd: content.travelListAd,
                travelHardAd: content.travelHardAd,
                promoteInTravel: content.promoteInTravel,
            },
            centerData: {
                autoTitle: content.autoTitle,
                autoList: content.autoList,
                shopInAuto,
                autoListAd: content.autoListAd,
                autoRSAd: content.autoRSAd,
                replaceShopConfig2: content.replaceShopConfig2,
            },
            rightData: {
                newCarGuideTitle: content.newCarGuideTitle,
                newCarGuideList1: content.newCarGuideList1,
                newCarGuideListAd: content.newCarGuideListAd,
            },
            autoAndGuideHardAd: content.autoAndGuideHardAd,
        };

        // 体育、娱乐、影视视频
        const boxData5 = {
            leftData: {
                sportsTitle: content.sportsTitle,
                sportsList: content.sportsList,
                shopInSports,
                sportsHardAd: content.sportsHardAd,
                promoteInSports: content.promoteInSports,
                sportRSAd: content.sportRSAd,
                replaceShopConfig: content.replaceShopConfig,
            },
            centerData: {
                entTitle: content.entTitle,
                entListTitle: content.entListTitle,
                entList0: content.entList0,
                shopInEnt,
                entList1: content.entList1,
                entNewsBox: content.entNewsBox,
                entRSAd: content.entRSAd,
                replaceShopConfig2: content.replaceShopConfig2,
            },
            rightData: {
                movieTitle: content.movieTitle,
                movieList0: content.movieList0,
                shopInMovie,
                movieList1: content.movieList1,
                movieRSAd: content.movieRSAd,
                replaceShopConfig: content.replaceShopConfig,
            },
            entAndMovieHardAd: content.entAndMovieHardAd,
        };

        // 美食、时尚、电商研究院，酒业
        const boxData6 = {
            leftData: {
                foodTitle: content.foodTitle,
                foodList0: content.foodList0,
                shopInFood0,
                foodList1: content.foodList1,
                foodList2: content.foodList2,
                shopGroup1,
                shopInFood1,
                foodListAd: content.foodListAd,
                foodListRSAd: content.foodListRSAd,
                foodAd: content.foodAd,
                promoteInFood: content.promoteInFood,
                foodRSAd1: content.foodRSAd1,
                foodRSAd2: content.foodRSAd2,
                foodRSAd3: content.foodRSAd3,
                foodRSAd4: content.foodRSAd4,
                replaceShopConfig: content.replaceShopConfig,
            },
            centerData: {
                fashionTitle: content.fashionTitle,
                fashionList0: content.fashionList0,
                shopInFashion,
                fashionList1: content.fashionList1,
                fashtionAd: content.fashtionAd,
                fashionRSAd: content.fashionRSAd,
                replaceShopConfig: content.replaceShopConfig,
            },
            rightData: {
                jiuTitle: content.jiuTitle,
                shopRegionList1: content.shopRegionList1,
                shopRegionList2,
                shopRegionList3: content.shopRegionList3,
                shopRegionList4,
                shopAd: content.shopAd,
                jiuList: content.jiuList,
                jiuARSAd: content.jiuARSAd,
                jiuBRSAd: content.jiuBRSAd,
                replaceShopConfig2: content.replaceShopConfig2,
            },
            fashionAd: content.fashionAd,
        };

        // 评测、房产、风财讯
        const boxData7 = {
            leftData: {
                pingceTitle: content.pingceTitle,
                pingceList: content.pingceList,
                homeHardAd: content.homeHardAd,
                promoteInHome: content.promoteInHome,
            },
            centerData: {
                houseAreaMap: content.houseAreaMap,
                houseTitle: content.houseTitle,
                houseList0: content.houseList0,
                houseList1: content.houseList1,
                houseList2: content.houseList2,
                houseList3: content.houseList3,
                areaList: {
                    guangzhouHouseTitle: content.guangzhouHouseTitle,
                    guangzhouHouse: content.guangzhouHouse,
                    qingdaoHouse: content.qingdaoHouse,
                    dalianHouse: content.dalianHouse,
                    nanjingHouse: content.nanjingHouse,
                    hangzhouHouse: content.hangzhouHouse,
                    shanghaiHouse: content.shanghaiHouse,
                    xianHouse: content.xianHouse,
                    changshaHouse: content.changshaHouse,
                    jinanHouse: content.jinanHouse,
                    chengduHouse: content.chengduHouse,
                    chongqingHouse: content.chongqingHouse,
                    shenzhenHouse: content.shenzhenHouse,
                    tianjinHouse: content.tianjinHouse,
                    yantaiHouse: content.yantaiHouse,
                    wuhanHouse: content.wuhanHouse,
                    guangzhouHouseLast: content.guangzhouHouseLast,
                    qingdaoHouseLast: content.qingdaoHouseLast,
                    dalianHouseLast: content.dalianHouseLast,
                    nanjingHouseLast: content.nanjingHouseLast,
                    hangzhouHouseLast: content.hangzhouHouseLast,
                    shanghaiHouseLast: content.shanghaiHouseLast,
                    xianHouseLast: content.xianHouseLast,
                    changshaHouseLast: content.changshaHouseLast,
                    jinanHouseLast: content.jinanHouseLast,
                    chengduHouseLast: content.chengduHouseLast,
                    chongqingHouseLast: content.chongqingHouseLast,
                    shenzhenHouseLast: content.shenzhenHouseLast,
                    tianjinHouseLast: content.tianjinHouseLast,
                    yantaiHouseLast: content.yantaiHouseLast,
                    wuhanHouseLast: content.wuhanHouseLast,
                },
            },
            rightData: {
                fengcxTitle: content.fengcxTitle,
                fengcxList: content.fengcxList,
            },
            houseHardAd: content.houseHardAd,
        };

        // 读书 健康 国学
        const boxData8 = {
            leftData: {
                cultureBookTitle: content.cultureBookTitle,
                cultureBookList0: content.cultureBookList0,
                shopInCultureBook,
                cultureBookList1: content.cultureBookList1,
                techListAd: content.techListAd,
                techHardAd: content.techHardAd,
                promoteInEdu: content.promoteInEdu,
                bookRSAd: content.bookRSAd,
                replaceShopConfig2: content.replaceShopConfig2,
            },
            centerData: {
                healthTitle: content.healthTitle,
                healthList0: content.healthList0,
                shopInEdu,
                healthList1: content.healthList1,
                eduListAd: content.eduListAd,
                healthRSAd: content.healthRSAd,
                replaceShopConfig: content.replaceShopConfig,
            },
            rightData: {
                historyTitle: content.historyTitle,
                // historyList: content.historyList,
                guoxueList: content.guoxueList,
                digitalListAd: content.digitalListAd,
            },
            eduHardAd: content.eduHardAd,
        };

        // 公益、佛教，教育 家居
        const boxData9 = {
            leftData: {
                gongyiTitle: content.gongyiTitle,
                gongyiList: content.gongyiList,
                gongyiHardAd: content.gongyiHardAd,
                promoteInGongyi: content.promoteInGongyi,
            },
            centerData: {
                foTitle: content.foTitle,
                foList0: content.foList0,
                foList1: content.foList1,
                shopInGuoxue,
                foRSAd: content.foRSAd,
                replaceShopConfig: content.replaceShopConfig,
            },
            rightData: {
                eduTitle: content.eduTitle,
                eduList0: content.eduList0,
                shopInHealth,
                homeList: content.homeList,
                eduRSAd: content.eduRSAd,
                replaceShopConfig2: content.replaceShopConfig2,
            },
            foAndHealthHardAd: content.foAndHealthHardAd,
        };

        // 小说、有声，音乐、动漫
        const boxData11 = {
            leftData: {
                musicTitle: content.musicTitle,
                musicList: content.musicList,
                musicAd: content.musicAd,
                promoteInMusic: content.promoteInMusic,
            },
            centerData: {
                fictionTitle: content.fictionTitle,
                fictionAreaTitle: content.fictionAreaTitle,
                fictionList: content.fictionList,
                fictionBox: content.fictionBox,
                fictionAreaList: content.fictionAreaList,
                fictionAreaBox: content.fictionAreaBox,
            },
            rightData: {
                animeTitle: content.animeTitle,
                animeAreaTitle: content.animeAreaTitle,
                animeList: content.animeList,
                animeAreaList: content.animeAreaList,
            },
            fictionAd: content.fictionAd,
        };

        // 凰家尚品、关注凤凰
        const boxData12 = {
            shopTitle2: content.shopTitle2,
            leftData: {
                shopList3Ad: content.shopList3Ad,
            },
            centerData: {
                shopList4Ad: content.shopList4Ad,
            },
            rightData: {
                fengTitle: content.fengTitle,
                mediaTitle: content.mediaTitle,
                mediaList: content.mediaList,
                coorTitle: content.coorTitle,
                coorList: content.coorList,
            },
        };

        const qrCodeData = {
            qrCode: content.qrCode,
        };

        const floatAds = {
            floatAd1: content.floatAd1,
            floatAd2: content.floatAd2,
            floatAd3: content.floatAd3,
            floatAd4: content.floatAd4,
            floatAd5: content.floatAd5,
            floatAd6: content.floatAd6,
            floatAd7: content.floatAd7,
        };

        return (
            <div className={redConfig.isIpad ? styles.ipad_layout : styles.layout}>
                <NoSSR>
                    <LowBrower IE78={content.IE78} content={content.lowOptions} />
                </NoSSR>
                <NoSSR>
                    <Chip
                        id="50128"
                        type="struct"
                        title="页面飘红配置"
                        groupName="页面飘红配置"
                        content={redConfig.topImg}>
                        <AddRed
                            isIpad={redConfig.isIpad}
                            turnRed={turnRed}
                            handleCloseTurnRed={this.handleCloseTurnRed}
                        />
                    </Chip>
                </NoSSR>
                <Header IE78={content.IE78} content={headerData} />
                <Navigation content={navigationData} turnRed={turnRed} />
                <div className={styles.topBox}>
                    <NoSSR>
                        <Ad content={content.topAd0} styleName={styles.topDbox0} />
                        <Ad content={content.topAd1} styleName={styles.topDbox1} />
                    </NoSSR>
                </div>
                {/* 热点、要闻、视频  */}
                <NewsBox0 content={boxData0} />
                {/* 凤凰精选、凰家尚品、风直播 */}
                <NewsBox1 content={boxData1} />
                {/* 财经、股票、行情 */}
                <NewsBox2 content={boxData2} />
                {/* 文化读书、军事、历史 */}
                <NewsBox3 content={boxData3} />
                {/* 旅游、汽车、新车导购 */}
                <NewsBox4 content={boxData4} />
                {/* 体育、娱乐、影视视频 */}
                <NewsBox5 content={boxData5} />
                {/* 美食、时尚、电商研究院，酒业 */}
                <NewsBox6 content={boxData6} />
                {/* 家居、房产、风财讯 */}
                <NewsBox7 content={boxData7} />
                {/* 教育、文化、读书、历史、国学*/}
                <NewsBox8 content={boxData8} />
                {/* 公益、佛教、健康 */}
                <NewsBox9 content={boxData9} />
                {/* 小说、有声，音乐、动漫 */}
                <NewsBox11 content={boxData11} />
                {/* 凰家尚品、关注凤凰 */}
                <NewsBox12 content={boxData12} />
                <NoSSR>
                    <Ad content={content.bottomAd} styleName={styles.bottomDbox} />
                    <Chip id="132838" type="static" title="底部版权" groupName="底部" content={content.footer}>
                        <Footer />
                    </Chip>
                    <Chip id="260731" type="struct" title="二维码" groupName="二维码" content={qrCodeData}>
                        <QrCode />
                    </Chip>
                    <FloatAds content={floatAds} />
                    <ChipView />
                </NoSSR>
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default errorBoundary(Layout);
