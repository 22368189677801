import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
// import ShopList from '../../../components/shopList';
import Ad from '@ifeng-fe/ui_pc_ad';
import NoSSR from 'react-no-ssr';

class CenterNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { shopTitle0, shopList0, shopListAd },
        } = this.props;

        return (
            <div className={styles.center_box}>
                <Chip id="230179" type="struct" title="凰家尚品标题" groupName="凰家尚品" content={shopTitle0}>
                    <NewsTitle size="middle" isAd={true} />
                </Chip>
                <NoSSR>
                    <Ad content={shopListAd} styleName={styles.bottomDbox} />
                </NoSSR>
                {/* <Chip
                    id="260685"
                    type="struct"
                    title="凰家尚品列表1"
                    groupName="凰家尚品"
                    content={shopList0}
                    translate="handleShopStructData">
                    <ShopList hasTitleImg size="long" ad={shopListAd} sourceName="shopList0" />
                </Chip> */}
            </div>
        );
    }
}

export default errorBoundary(CenterNewsBox);
